import React, { useEffect, FC } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';

export interface SubCategoryProps {}

const SubCategory: FC<SubCategoryProps> = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const lastSegment = location.pathname.split('/').filter(Boolean).pop();

    let listData:any = localStorage.getItem("categoryData")
    const categoryData = JSON.parse(listData);

    useEffect(() => {
        // Redirect to another path, for example "/new-path"
       if (categoryData != null) {
            const mainCategory = categoryData.filter((item: any) => item.subCategory.length>0);
            mainCategory.map((item: any) => {
                if (item.subCategory) {
                    let subCategory=item.subCategory;
                    let  slugname= subCategory.filter((subitem: any) => subitem.slug===lastSegment);
                    if(slugname.length>0){
                        navigate("/"+item.slug+"/"+lastSegment);
                    }
                }
            });
        }
    }, [navigate]);

    return null;
};

export default SubCategory;
