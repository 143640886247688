import Page404 from "containers/Page404/Page404";
import PageLogin from "containers/PageLogin/PageLogin";
import ForgotPass from "containers/PageLogin/ForgotPass";
import PageSignup from "containers/PageSignUp/PageSignUp";
import PageAbout from "containers/PageAbout/PageAbout";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PageCollection2 from "containers/PageCollection2";
import PageContact from "containers/PageContact/PageContact";
import PageHome from "containers/PageHome/PageHome";
import Product from "containers/Product/Product";
import CartPage from "containers/ProductDetailPage/CartPage";
import ProductDetail from "containers/Product/ProductDetail";
import SiteHeader from "containers/SiteHeader";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "shared/Footer/Footer";
import ScrollToTop from "./ScrollToTop";
import { Page } from "./types";
import CategoryProduct from "containers/Category/CategoryCollection";
import SubCategory from "containers/Category/SubCategory";
import Category from "containers/Category/Category";
import PressTool from "containers/Category/PressTool";

// ErrorBoundary page
import ErrorBoundary from "../ErrorBoundary";

// Admin //
import AdminPageInquiryDetail from "containers/Admin/Inquiry/view";
import EnquiryPdfView from "containers/Admin/Inquiry/EnquiryPdfView";
import AdminInquiry from "containers/Admin/Inquiry/Index";
import AdminContactInquiry from "containers/Admin/Contact/Index";
import AdminContactInquiryDetail from "containers/Admin/Contact/View";
import AdminDashboard from "containers/Admin/Dashboard/PageDashboard";
import AdminPageLogin from "containers/Admin/Login/Login";
import AdminLogout from "containers/Admin/Logout/Logout";
import CustomerLogout from "containers/Logout/Logout";
import Account from "containers/AccountPage/AccountPage";

import AdminProduct from "containers/Admin/Product/Index";
import AdminProductCreate from "containers/Admin/Product/Create";
import AdminProductEdit from "containers/Admin/Product/Edit";
import AdminProductDetail from "containers/Admin/Product/View";

import AdminUser from "containers/Admin/User/Index";
import AdminUserDetail from "containers/Admin/User/View";


import AdminCustomer from "containers/Admin/Customer/Index";
import AdminCustomerDetail from "containers/Admin/Customer/View";

import AdminCategory from "containers/Admin/Category/Index";
import AdminCategoryDetail from "containers/Admin/Category/View";

import { Toaster } from "react-hot-toast";

import { Link, useNavigate, useParams, useLocation } from "react-router-dom";



export const pages: Page[] = [
  //Home Page
  { path: "/", component: PageHome },

  //About Us Page
  { path: "/about", component: PageAbout }, 

  //Product List Page
  { path: "/products", component: Product },
 // { path: "/mould-accessories", component: Product },
 // { path: "/press-tool-accessories", component: Product },

  //Category
  { path: "/category", component: Category },
  { path: "/press-tool", component: PressTool },

  //Product Detail Page
  // { path: "/:slug", component: ProductDetail },

  //New Product Detail Page
  { path: "/category/:slug/:slug", component: ProductDetail },
  { path: "/category/:slug", component: SubCategory },

  //Enquiry Page
  { path: "/page-collection", component: PageCollection2 },

  //Contact Us Page
  { path: "/contact", component: PageContact },

  //Cart Page
  { path: "/cart", component: CartPage },

  //Login Page
  { path: "/login", component: PageLogin },
  { path: "/forgot-pass", component: ForgotPass },

  //Sign Up Page
  { path: "/signup", component: PageSignup },

  //Logout Customer
  { path: "/logout", component: CustomerLogout },

   //Logout Customer
  { path: "/account", component: Account },

   //Product List Page
   { path: "/:slug", component: Product },
   { path: "/:slug/:slug", component: CategoryProduct },

];

export const checkoutPage: Page[] = [
  //Checkout Page
  { path: "/checkout", component: CheckoutPage },
];

export const withoutHeaderPage: Page[] = [
  //Admin Login
  { path: "/admin/login", component: AdminPageLogin },
];

export const adminPages: Page[] = [
  //Admin Logout
  { path: "/admin/logout", component: AdminLogout },
  //Admin Dashboard
  { path: "/admin/dashboard", component: AdminDashboard },

  { path: "/admin/users", component: AdminUser },
  { path: "/admin/user/:id", component: AdminUserDetail },
  { path: "/admin/customers", component: AdminCustomer },
  { path: "/admin/customer/:id", component: AdminCustomerDetail },

  { path: "/admin/dashboard", component: AdminDashboard },
  //Admin Product List
  { path: "/admin/products", component: AdminProduct },

  //Admin Product Details
  { path: "/admin/product/create", component: AdminProductCreate },
  { path: "/admin/product/edit/:id", component: AdminProductEdit },
  { path: "/admin/product/:id", component: AdminProductDetail },
  //Admin Enquiry List
  { path: "/admin/inquiry", component: AdminInquiry },
  { path: "/admin/inquiry/:id", component: AdminPageInquiryDetail },
  { path: "/admin/enquiry-pdf/:id", component: EnquiryPdfView },

  //Admin Enquiry List
  { path: "/admin/contact/inquiry", component: AdminContactInquiry },
  { path: "/admin/contact/inquiry/:id", component: AdminContactInquiryDetail },

  //Admin Product List
  { path: "/admin/categories", component: AdminCategory },
  { path: "/admin/categories/:id", component: AdminCategoryDetail },

];


const MyRoutes = () => {
  return (

    <BrowserRouter>
      <ErrorBoundary>
        <Toaster />
        <ScrollToTop />
        {((window.location.pathname !== "/admin/login") && (!window.location.pathname.startsWith("/admin/enquiry-pdf/"))) ? <SiteHeader /> : ''}{" "}
        {/* {window.location.pathname !==  ? <SiteHeader /> : ''}{" "} */}

        <Routes>
          {pages.map(({ component: Component, path }, index) => {
            return <Route key={index} element={<Component />} path={path} />;
          })}
          {checkoutPage.map(({ component: Component, path }, index) => {
            return <Route key={index} element={<Component />} path={path} />;
          })}
          {adminPages.map(({ component: Component, path }, index) => {
            /*  if (!localStorage.getItem("AuthUser")) {
              return (
                <Route
                  key={index}
                  element={<AdminPageLogin />}
                  path="/admin/login"
                />
              );
            } */
            return <Route key={index} element={<Component />} path={path} />;
          })}

          {withoutHeaderPage.map(({ component: Component, path }, index) => {
            return <Route key={index} element={<Component />} path={path} />;
          })}
          <Route path="*" element={<Page404 />} />
        </Routes>
        {(!window.location.pathname.startsWith("/admin/enquiry-pdf/")) ? <Footer /> : ''}{" "}
      </ErrorBoundary>
    </BrowserRouter>

  );
};

export default MyRoutes;
